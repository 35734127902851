import React from "react";
import { Grid } from "semantic-ui-react";
import { SettingsBlock } from "../index.styles";
import LedColor from "./LedColor";
import Transmissivity from "./Transmissivity";
import MinimumAutonomy from "./MinimumAutonomy";
import { Params, Settings } from "../../../types";
import MinimumALr from "./MinimumAlr";

type Props = {
  updateSettings: (settings: Settings) => void;
  params: Params;
};

const Other = ({ updateSettings, params }: Props): React.ReactElement => (
  <SettingsBlock>
    <Grid>
      <LedColor updateSettings={updateSettings} params={params} />
      <Transmissivity updateSettings={updateSettings} params={params} />
      <MinimumAutonomy updateSettings={updateSettings} params={params} />
      <MinimumALr updateSettings={updateSettings} params={params} />
    </Grid>
  </SettingsBlock>
);

export default Other;
