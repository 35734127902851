import debounce from "debounce";
import React, { useMemo } from "react";

import { Form, Grid, Input, InputOnChangeData } from "semantic-ui-react";
import { Params, Settings } from "../../../types";

type Props = {
  params: Params;
  updateSettings: (settings: Settings) => void;
};

const MinimumALr = (props: Props): React.ReactElement => {
  const settings = props.params.settings;

  function onChangeValue(data: InputOnChangeData, settings: Settings) {
    const value = data.value || "0";
    props.updateSettings({
      ...settings,
      minimumAlr: parseFloat(value),
    });
  }
  const debouncedOnChangeValue = useMemo(
    () => debounce(onChangeValue, 750),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const minimumAlr = settings.minimumAlr;

  return (
    <Grid.Row style={{ alignItems: "center" }}>
      <Grid.Column computer={9} tablet={8} mobile={8}>
        Minimum ALR
      </Grid.Column>
      <Grid.Column computer={7} tablet={8} mobile={8}>
        <Form>
          <Input
            fluid
            placeholder="0"
            type="number"
            min={0.1}
            step={0.05}
            id="minimumAlr"
            defaultValue={minimumAlr}
            onChange={(_, data) => {
              debouncedOnChangeValue(data, settings);
            }}
          />
        </Form>
      </Grid.Column>
    </Grid.Row>
  );
};

export default MinimumALr;
